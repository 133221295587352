import Higher from "../About/higher"
import Client from '../About/client';
import Expert from '../About/expert';
import Offer from '../About/offer';
import Tech from '../About/tech';
import Get from '../About/get';
import Cursor from '../Cursor';

function About() {
  return (
    <>
      <Higher />
      <Client />
      <Expert />
      <Offer />
      <Tech />
      <Cursor />
    </>
  )
}
export default About;
