import Message from '../Message/messages'
function Messag() {

  return (
    <>
      <Message />
    </>
  );
}
export default Messag;
