
import Complete from "../Home/complete";
import Cube from "../Home/cube";
import Discover from "../Home/discover";
import Tech from "../Home/technology";
import Stunn from "../Home/stunning";
import Store from "../Home/store";
import Every from "../Home/everything";
import Trun from "../Home/trun";
import Cost from "../Home/costumer";
import Get from "../Home/get";
import Cursor from "../Cursor";
import Local from "../Blog/local";
import Head from "../Home/head";
function Home() {
    return (
        <>
            <Head />
            <Complete />
            <Cube />
            <Discover />
            <Tech />
            <Stunn />
            <Store />
            {/* <Every />
            // <Trun /> */}

            <Cost />
            <Cursor />

        </>
    )
}
export default Home;