import Expand from "../Pageapp/expand";
import Explore from "../Pageapp/explore";
import Brand from "../Pageapp/brand";
import Custum from "../Pageapp/custom";
import Get from '../Pageapp/get'
import Cursor from "../Cursor";
function Connect(){

    return(
        <>
        <Expand/>
        <Explore/>
        <Brand/>
        <Custum/>
       <Cursor/>
        </>
    )
}
export default Connect;