
function Boot() {
    return (
        <>
            <div className="boot--section-perfrom">
                <div className="wrapper">
                    <div className="container">
                        <div className="boot--section-perfrom-element">
                            <h2>Let's <span style={{ color: "#5a853d" }}>Boot Your Rankings:</span> Request Your <span style={{ color: "#fc5569" }}> SEO Quote</span></h2>
                            <p>Use HubSyntax to grow your Shopify plus brand via checkout upsells, one-click upsells  and thank you page customization. Enjoy complimentary migration and frictionless implementatioin with a your own plus account manager.</p>
                            <button className='more-figma'>
                            <span className="rotate-container">
                                  <span className="rotate-text">T</span>
                                  <span className="rotate-text">a</span>
                                  <span className="rotate-text">l</span>
                                  <span className="rotate-text">k</span>
                                  <span className="rotate-text">&nbsp;</span>
                                  <span className="rotate-text">t</span>
                                  <span className="rotate-text">o</span>
                                  <span className="rotate-text">&nbsp;</span>
                                  <span className="rotate-text">e</span>
                                  <span className="rotate-text">x</span>
                                  <span className="rotate-text">o</span>
                                  <span className="rotate-text">r</span>
                                  <span className="rotate-text">t</span>
                          
                                  </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Boot;