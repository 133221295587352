import Today from "../Contact/today";
import Cube from "../Contact/cube";
import New from "../Contact/new";
// import Get from '../Pageapp/get'
import Cursor from "../Cursor";
function Contact(){
    return(
        <>
        <Today/>
        <Cube/>
        <New/>
        <Cursor/>
        </>
    )
}
export default Contact;