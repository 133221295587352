import IMAGES from "../Allfiles/image";
function Expert() {
    return (
        <>
            <div className="expsion--section">
                <div className = "wrapper">
                <div className="container">
                    <div className='expsion--section-main'>
                    <div className="expsion--section-main-side">
                        <div className="expsion--section-first">
                        <img src={IMAGES.w7} />
                        </div>
                        <div className="expsion--section-second">
                            <div className="expsion--section-second-side">
                                <div className="expsion--section-form">
                                    <div className="expsion--section-form--side">
                                        <img src={IMAGES.w6} /> </div>
                                    <div className="expsion--section-form--world">
                                        <h4>From hello world to IPO.</h4></div>
                                </div>
                                <h2>At  <span style={{ color: "#FF546A" }}> Hubsyntax,</span> our expsion direct result wavering coment to helping  <span style={{ color: "#3A8634" }}>  chants achieve </span> </h2>
                                <p>Use Hubsyntax to grow your Shopify plus brand via checkout upsells, one-click upsells and thank you page customization. Enjoy complimentary migration and frictionless implementation with a your own plus account manager.</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div >
        </>
    )
}
export default Expert;