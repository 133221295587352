import Need from "../Speed/Need";
import Fastan from "../Speed/Fastan";
import Matter from "../Speed/Matter";
import Effect from "../Speed/Effect";
import After from "../Speed/After";
import Boot from "../Speed/Boot";
import Get from "../Speed/get";
import Cursor from "../Cursor";

function Speed(){

    return(
        <>
        <Need/>
        <Fastan/>
        <Matter/>
        <Effect/>
        <After/>
        <Boot/>
        <Cursor/>
        </>
    )
}
export default Speed;       