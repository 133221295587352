import shop from '../images/Layer 29.png';
import home from '../images/Home.png';
import Service from '../images/Service.png';
import blog from '../images/about.png';
import about from '../images/blog.png';
import Build1 from '../images/Build1.png';
import storecustomization from '../images/store customization.png';
import Designstore1 from '../images/Designstore1.png';
import migration00 from '../images/migration00.png';
import speedoptimization from '../images/speed optimization.png';
import SEO10 from '../images/SEO10.png';
import logo from '../images/logo.png';
import ss from '../images/ss.png';
import hed from '../images/hede.png';
import full from '../images/full2.png';
import shop1 from '../images/shopf.png';
import wix from '../images/wix.png';
import cube1 from '../images/ify.png';
import cube2 from '../images/port.png';
import cube3 from '../images/ic3.png';
import cube4 from '../images/ops.png';
import cube5 from '../images/bols.png';
import cube6 from '../images/nik.png';
import ico from '../images/icon 01.png';
import ico1 from '../images/02.png';
import ico2 from '../images/03.png';
import s1 from '../images/img2.png';
import balm from '../images/berry.png';
import anim1 from '../images/anim1.png';
import anim2 from '../images/anim2.png';
import baar from '../images/baar.png';
import br from '../images/br.png';
import temp from '../images/temp.png'
import shopify from '../images/shopify.png'
import php from '../images/php.png'
import node3 from '../images/node3.png'
import ajex from '../images/ajex.png';
import ring from '../images/ring.png'
import aa from '../images/an1.png';
import aa1 from '../images/an2.png';
import aa2 from '../images/an3.png';
import aa3 from '../images/an4.png';
import aa4 from '../images/an5.png';
import aa5 from '../images/an6.png';
import king from '../images/king.png'
import star from '../images/star.png'
import shopi from '../images/Layer 29.png';
import fb from '../images/face.png'
import ins from '../images/inst.png'
import twi from '../images/oo.png'
import ink from '../images/io.png'
import pl from '../images/pol.png'
import f from '../images/f.png'
import i from '../images/i.png'
import ini from '../images/in.png'
import p from '../images/p.png'
import t from '../images/t.png'
import bag from '../images/bg2.png';
// import f1 from '../images/logo-main_binthereboxthat_3.png'
import f2 from '../images/ban.png'
import f3 from '../images/Celina.1ad8e2f5c5b8cfa8f842 (1).png'
import f4 from '../images/download (13).png'
import f5 from '../images/Group 11 Frame.png'
import f7 from '../images/download (17).png'
import f8 from '../images/download (18).png'
import f9 from '../images/fum.png'
import w6 from '../images/ss.png'
import w7 from '../images/sec1.png'
import o1 from '../images/o0.png'
import n1 from '../images/node.png';
import n2 from '../images/n2.png';
import n3 from '../images/n3.png';
import im1 from '../images/an5.png'
import im2 from '../images/an3.png'
import left from '../images/lefta.png'
import right from '../images/ri.png'
import g1 from '../images/Group 26.png'
import g2 from '../images/Group 27.png'
import g3 from '../images/Group 29.png'
import g4 from '../images/Group 30.png'
import g5 from '../images/Group 31.png'
import g6 from '../images/Group 33.png'
import g7 from '../images/Group 35.png'
import g9 from '../images/Group 25.png'
import g10 from '../images/Group 28.png'
import g11 from '../images/62e1784d32ece27b4ee7733f.png'
import g13 from '../images/Group 32.png'
import g14 from '../images/Group 34.png'
import g15 from '../images/Group 36.png'
import se from '../images/sec2.png'
import z1 from '../images/ss.png'
import z2 from '../images/img2.png'
import insta from '../images/instagram (6).png';
import pin from '../images/pinterest.png';
import you from '../images/youtube (4).png';
import lock from '../images/linkedin.png';
import page1 from '../images/Rectangle 36 copy 7-2.png'
import page2 from '../images/Rectangle 36 copy 7-1.png'
import page3 from '../images/Layer 130.png'
import page4 from '../images/Rectangle 36 copy 7.png'
import pag from '../images/cont1.png'
import pag1 from '../images/cont2.png'
import react1 from '../images/Rectangle 36.png'
import react2 from '../images//Rectangle 36 copy.png'
import react3 from '../images/Rectangle 36 copy 2.png'
import leftt from '../images/lefta.png'
import rightt from '../images/ri.png'
import port from '../images/Untitled-3.png'
import copy5 from '../images/Rectangle 36 copy 5.png'
import copy6 from '../images/Rectangle 36 copy 3.png'
import copy3 from '../images/Rectangle 36 copy 3-1.png'
import copy2 from '../images/Rectangle 36 copy 4.png'
import copy7 from '../images/Rectangle 36 copy 6.png'
import p1 from '../images/Untitled-4.png'
import p2 from '../images/Untitled-4EFD.png'
import p3 from '../images/DFFD.png'
import p4 from '../images/Untitled-4FDVFDB.png'
import p5 from '../images/Untitled-4DSCDF.png'
import p6 from '../images/Untitled-4DSVFD.png'
import ag from '../images/Rectangle 35 copy 9.png'
import ag1 from '../images/Rectangle 35 copy 10.png'
import ag2 from '../images/Rectangle 35 copy 11.png'
import ag3 from '../images/9028582.png'
import pay from '../images/pay.png';
import yellow from '../images/yellow.png';
import stripe from '../images/stripe.png';
import goggle from '../images/goggle.png';
import black from '../images/black.png';
import visa from '../images/visa.png';
import pa from '../images/pa.png';
import sm from '../images/img2.png';
import bu4 from '../images/bu4.png';
import firt from '../images/firt.png';
import ps from '../images/ps.png';
import id from '../images/id.png';
import ai from '../images/ai.png';
import my from '../images/mysh.png';
import bu7 from '../images/bu7.png';
import sme from '../images/img2.png';
import bu5 from '../images/bu5.png';
import ph3 from '../images/ph3.png';
import html from '../images/html.png';
import js from '../images/js.png';
import css from '../images/css.png';
import bu1 from '../images/bu2.png'
import bu2 from '../images/6707259.png'
import s10 from '../images/s10.png'
import s23 from '../images/fi.png'
import sm11 from '../images/img2.png';
import s11 from '../images/sh1.png';
import sho from '../images/sho.png';
import sk from '../images/sk.png';
import s55 from '../images/v1.png';
import r3 from '../images/rank3.png';
import r4 from '../images/inspection copy 3.png';
import r5 from '../images/conversation copy 3.png';
import r6 from '../images/conversation copy 4.png';
import r7 from '../images/rank4.png';
import r8 from '../images/rank5.png';
import r9 from '../images/rank6.png';
import r2 from '../images/rank2.png';
import s101 from '../images/s10.png'
import r1 from '../images/rank1.png'
import smee from '../images/img2.png';
import g23 from '../images/Group 3332.png';
import f23 from '../images/Group 366.png'
import g233 from '../images/Group 355.png';
import s1011 from '../images/s10.png';
import grop33 from '../images/k1.png';
import grp33 from '../images/sh.png';
import gr33 from '../images/ga.png';
import gr3 from '../images/red.png';
import g34 from '../images/Group 342.png'
import sm00 from '../images/img2.png';
import third from '../images/third.png';
import four from '../images/four.png';
import ch from '../images/inspection.png';
import ch1 from '../images/conversation.png';
import ch2 from '../images/preferences.png';
import ch3 from '../images/maintenance.png';
import ch4 from '../images/resize.png';
import FIVE from '../images/FIVE.png';
import s100 from '../images/s10.png'
import aa10 from '../images/first.png'
import sec from '../images/second.png'
import f12 from '../images/qq1.png'
import f32 from '../images/6707259.png';
import w21 from '../images/aq.png'
import z11 from '../images/f1.png'
import z21 from '../images/f2.png'
import p11 from '../images/q1.png'
import p21 from '../images/q2.png'
import p31 from '../images/q3.png'
import s111 from '../images/d1.png'
import s21 from '../images/d2.png'
import s31 from '../images/g1.png'
import supe from '../images/super.png';
import a0 from '../images/an2.png'
import a1 from '../images/an5.png'
import a2 from '../images/an3.png'
import a9 from '../images/sc1.png'
import ff1 from '../images/af.png';
import jh from '../images/j.png';
import smm from '../images/img2.png';
import k from '../images/kk.png';
import pd from '../images/pad.png'
import fw1 from '../images/fr.png';
import h0 from '../images/h0.png';
import company1 from '../images/company1.png';
import company2 from '../images/company2.png';
import company3 from '../images/company3.png';
import company4 from '../images/company4.png';
import company5 from '../images/company5.png';
import company6 from '../images/company6.png';
import company7 from '../images/company7.png';
import company8 from '../images/company8.png';
import company9 from '../images/company9.png';
import company10 from '../images/company10.png';
import whitelogo from '../images/whitelogo.png';

const IMAGES = {
    whitelogo: whitelogo,
    company10: company10,
    company9: company9,
    company8: company8,
    company7: company7,
    company6: company6,
    company5: company5,
    company4: company4,
    company3: company3,
    company2: company2,
    company1: company1,
    h0: h0,
    fw1: fw1,
    pd: pd,
    smm: smm,
    k: k,
    jh: jh,
    ff1: ff1,
    a0: a0,
    a1: a1,
    a2: a2,
    a9: a9,
    supe: supe,
    z11: z11,
    z21: z21,
    p11: p11,
    p21: p21,
    p31: p31,
    s111: s111,
    s21: s21,
    s31: s31,
    w21: w21,
    f12: f12,
    f32: f32,
    sec: sec,
    s100: s100,
    aa10: aa10,
    four: four,
    ch: ch,
    ch1: ch1,
    ch2: ch2,
    ch3: ch3,
    ch4: ch4,
    FIVE: FIVE,
    sm00: sm00,
    third: third,
    g34: g34,
    s1011: s1011,
    grop33: grop33,
    grp33: grp33,
    gr33: gr33,
    gr3: gr3,
    g233: g233,
    f23: f23,
    smee: smee,
    g23: g23,
    s101: s101,
    r1: r1,
    r2: r2,
    r3: r3,
    r5: r5,
    r4: r4,
    r6: r6,
    r7: r7,
    r8: r8,
    r9: r9,
    s55: s55,
    sk: sk,
    sho: sho,
    sm11: sm11,
    s11: s11,
    s10: s10,
    s23: s23,
    bu1: bu1,
    bu2: bu2,
    bu5: bu5,
    ph3: ph3,
    html: html,
    js: js,
    css: css,
    bu7: bu7,
    sme: sme,
    firt: firt,
    ps: ps,
    id: id,
    ai: ai,
    my: my,
    sm: sm,
    bu4: bu4,
    pay: pay,
    yellow: yellow,
    stripe: stripe,
    goggle: goggle,
    black: black,
    visa: visa,
    pa: pa,
    ag: ag,
    ag2: ag2,
    ag3: ag3,
    ag1: ag1,
    p1: p1,
    p2: p2,
    p3: p3,
    p4: p4,
    p5: p5,
    p6: p6,
    copy5: copy5,
    copy6: copy6,
    copy3: copy3,
    copy2: copy2,
    copy7: copy7,
    port: port,
    react1: react1,
    react2: react2,
    react3: react3,
    leftt: leftt,
    rightt: rightt,
    page1: page1,
    page2: page2,
    page3: page3,
    page4: page4,
    pag: pag,
    pag1: pag1,
    insta: insta,
    pin: pin,
    you: you,
    lock: lock,
    g1: g1,
    g2: g2,
    g3: g3,
    g4: g4,
    g5: g5,
    g6: g6,
    g7: g7,
    g9: g9,
    g10: g10,
    g11: g11,
    g13: g13,
    g14: g14,
    g15: g15,
    se: se,
    z1: z1,
    z2: z2,
    im1: im1,
    im2: im2,
    left: left,
    right: right,
    o1: o1,
    n1: n1,
    n2: n2,
    n3: n3,
    // 2: f1,
    f2: f2,
    f3: f3,
    f4: f4,
    f5: f5,
    f7: f7,
    f8: f8,
    f9: f9,
    w6: w6,
    w7: w7,
    shopi: shopi,
    fb: fb,
    ins: ins,
    twi: twi,
    ink: ink,
    pl: pl,
    f: f,
    i: i,
    ini: ini,
    p: p,
    t: t,
    bag: bag,
    king: king,
    star: star,
    aa: aa,
    aa1: aa1,
    aa2: aa2,
    aa3: aa3,
    aa4: aa4,
    aa5: aa5,
    temp: temp,
    shopify: shopify,
    php: php,
    node3: node3,
    ajex: ajex,
    ring: ring,
    shop: shop,
    home: home,
    Service: Service,
    blog: blog,
    about: about,
    Build1: Build1,
    storecustomization: storecustomization,
    Designstore1: Designstore1,
    speedoptimization: speedoptimization,
    SEO10: SEO10,
    logo: logo,
    migration00: migration00,
    ss: ss,
    hed: hed,
    ss: ss,
    full: full,
    shop1: shop1,
    wix: wix,
    cube1: cube1,
    cube2: cube2,
    cube3: cube3,
    cube4: cube4,
    cube5: cube5,
    cube6: cube6,
    ico: ico,
    ico1: ico1,
    ico2: ico2,
    s1: s1,
    balm: balm,
    anim1: anim1,
    anim2: anim2,
    baar: baar,
    br: br,

}
export default IMAGES;