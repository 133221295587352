import React from 'react';

function Animation() {
    return (
        <div className="animation">
            <div className="cirle">
                <div className="cirle2">
                    {/* <div className="loader">
                     
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Animation;
