import IMAGES from "../Allfiles/image";

function Cont() {
  return (
    <>
      <div className="content--section-desire">
        <div className="wrapper">
          <div className="container">
            <div className="content--section-desire-process">
              <h2>Content</h2>
              <p>Content is the process of generating awareness, interest, and desire for a <span style={{ color: "#FC5569" }}> product or service.</span></p>
            </div>
            <div className="content--section-desire-content">
              <div className="content--section-all-template">
                <img src={IMAGES.pag} />
                <div className='content--section-all-template-desire'>
                  <button id='team'>Content</button>
                  <h3>4 FAQ Templates + Examples to Inspire Your <span style={{ color: "#FC5569" }}>FAQ Page</span></h3>
                  <p>Learn how to remove outdated --section-desire from search results with these two Google tools.</p>
                  <div className='lee1'>
                    <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                    <p>2 November 2020</p>
                  </div>
                </div>
              </div>
              <div className="content--section-all-template">
                <img src={IMAGES.pag1} />
                <div className='content--section-all-template-desire-team'>
                  <button id='team'>Content</button>
                  <h3>SEO and Content Marketing: How to Com <span style={{ color: "#FC5569" }}> bine Them...</span></h3>
                  <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                  <div className='lee1'>
                    <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                    <p>2 November 2020</p>
                  </div>
                </div>
              </div>
              <div className="content--section-all-template">
                <img src={IMAGES.page3} />
                <div className='content--section-all-template-desire'>
                  <button id='team'>Content</button>
                  <h3>15 Effective Content Marketing Tips to SK <span style={{ color: "#FC5569" }}>rocket Your Results</span></h3>
                  <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                  <div className='lee1'>
                    <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                    <p>2 November 2020</p>
                  </div>
                </div>
              </div>
              <div className="content--section-all-template">
                <img src={IMAGES.page4} />
                <div className='content--section-all-template-desire'>
                  <button id='team'>Content</button>
                  <h3>What is Clickbait? Does it Actually Work + 3 <span style={{ color: "#FC5569" }}>Examples</span></h3>
                  <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                  <div className='lee1'>
                    <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                    <p>2 November 2020</p>
                  </div>
                </div>
              </div>
              <div className="content--section-all-template">
                <img src={IMAGES.page2} />
                <div className='content--section-all-template-desire'>
                  <button id='team'>Content</button>
                  <h3>5 Time-Tested Blog Post Templates for Com <span style={{ color: "#FC5569" }}>pelling Content</span></h3>
                  <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                  <div className='lee1'>
                    <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                    <p>2 November 2020</p>
                  </div>
                </div>
              </div>
              <div className="content--section-all-template">
                <img src={IMAGES.page1} />
                <div className='content--section-all-template-desire'>
                  <button id='team'>Content</button>
                  <h3>Evergreen Content: What It Is & How to <span style={{ color: "#FC5569" }}>Create it</span></h3>
                  <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                  <div className='lee1'>
                    <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                    <p>2 November 2020</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Cont;