import IMAGES from "../Allfiles/image";
function Seo() {

    return (

        <>
            <div className="seo--section-google">
                <div className='target'>
                    <div className = "wrapper">
                    <div className="container">
                        <div className="seo--section-rank">
                            <h2>SEO</h2>
                            <p>If you want to rank higher in search engines like Google, <span style={{ color: "#FC5569" }}> you need SEO.</span></p>
                        </div>
                        <div className="seo--section-rank-remover">
                            <div className="seo--section-rank-tool">
                                <img src={IMAGES.copy5} />
                                <div className='seo--section-rank-result'>
                                    <button id='two'>SEO</button>
                                    <h3>How to Remove Outdated Content Using  <span style={{ color: "#FC5569" }}> Google Tools </span></h3>
                                    <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                                    <div className='lee'>
                                        <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                                        <p>2 November 2020</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seo--section-rank-tool">
                                <img src={IMAGES.copy6} />
                                <div className='seo--section-rank-result'>
                                    <button id='two'>SEO</button>
                                    <h3>Leveraging Google Ads to Reach Your  <span style={{ color: "#FC5569" }}> Target Audience </span></h3>
                                    <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                                    <div className='lee'>
                                        <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                                        <p>2 November 2020</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seo--section-rank-tool">
                                <img src={IMAGES.copy3} />
                                <div className='seo--section-rank-result'>
                                    <button id='two'>SEO</button>
                                    <h3>Why You Should Set Up Enhanced Google Analytics for Your  <span style={{ color: "#FC5569" }}> Shopify Store?</span></h3>
                                    <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                                    <div className='lee'>
                                        <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                                        <p>2 November 2020</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seo--section-rank-tool">
                                <img src={IMAGES.copy2} />
                                <div className='seo--section-rank-result'>
                                    <button id='two'>SEO</button>
                                    <h3>How to Remove Outdated Content Using<span style={{ color: "#FC5569" }}> Google Tools</span></h3>
                                    <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                                    <div className='lee'>
                                        <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                                        <p>2 November 2020</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seo--section-rank-tool">
                                <img src={IMAGES.copy5} />
                                <div className='seo--section-rank-result'>
                                    <button id='two'>SEO</button>
                                    <h3>Leveraging Google Ads to Reach Your  <span style={{ color: "#FC5569" }}> Target Audience </span></h3>
                                    <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                                    <div className='lee'>
                                        <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                                        <p>2 November 2020</p>
                                    </div>
                                </div>
                            </div>
                            <div className="seo--section-rank-tool">
                                <img src={IMAGES.copy7} />
                                <div className='seo--section-rank-result'>
                                    <button id='two'>SEO</button>
                                    <h3>Why You Should Set Up Enhanced Google Analytics for Your  <span style={{ color: "#FC5569" }}> Shopify Store?</span></h3>
                                    <p>Learn how to remove outdated content from search results with these two Google tools.</p>
                                    <div className='lee'>
                                        <h4> <span style={{ color: "#FC5569" }}> David lee</span></h4>
                                        <p>2 November 2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default Seo;